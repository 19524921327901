<template>
    <div>
        <slot name="prepend"></slot>
        <b-form-radio-group
            v-model="fieldForEvent"
            buttons
            button-variant="inline-secondary"
        >
            <field-editor
              v-for="field in fields"
              :key="field"
              :showSummary="true"
              :value="field"
              :size="sm"
              :canSetHotState="d_selected != null && d_selected.field_number == field.field_number"
              :showSelector="true"
              :isSelected="d_selected != null && d_selected.field_number == field.field_number"
              @selection_change="fieldSelection_change"
              @hotState_change="fieldHotState_change"
            ></field-editor>
        </b-form-radio-group>
        <slot name="postpend"></slot>
    </div>
</template>

<script>
//import { RST_FIELD, RST_EVENT } from "@/LocalDB";
import FieldEditor from '@/components/fieldEditor.vue';
 
export default{
    components:{ FieldEditor, },
    props:{
        fields: {type:Array, required:true},
        selected:{type:Object, default: null},
/*        competition_id: { type:String, required:true},
        round_number: {type:Number, required:true},
        level: { type:String, required:true},
        category: { type:String, required: true},
        eventType: { type:String, required:true},*/
    },
    data(){
        return {
            /*d_competition_id: this.competition_id,
            d_eventType: this.eventType,
            d_category: this.category,
            d_level: this.level,
            d_round_number: this.round_number,

            fieldForEvent: null,

            fields:[],*/
            d_selected: this.selected,
        }
    },
    methods:{
        /*async refresh(){
            this.fieldForEvent = null;
            this.fields = RST_FIELD.query().where("competition_id", this.d_competition_id).orderBy('field_number').get();
            var event = RST_EVENT.query()
                            .where("competition_id", this.d_competition_id)
                            .where("round_number", this.d_round_number)
                            .where("level", this.d_level)
                            .where("category", this.d_category)
                            .where("event", this.d_eventType)
                            .first();
            if(event && event!=null)
                this.fieldForEvent = this.fields.find(f => f.field_number == event.field_number);
        },*/
        fieldSelection_change(field){
            this.$emit('change', field.field_number);
        },
        fieldHotState_change(field){
            this.$emit('hotStateChange', field);
        }
    },
    async mounted(){
/*        await this.showRefresher();
        await this.refresh();
        this.hideRefresher();*/
    },
    watch:{
        selected: function(newVal){
            this.d_selected = newVal;
        }
    }
}
</script>
